.sale{
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: url("../images/hotels/background-image.jpg");
    height: 350px;
    background-position: center;
    background-size: cover;
}

.saleContainer{
    width: 1024px;
    color: white;
    margin: 50px 0;
}

.saleTitle{
    font-size: 30px;
    font-weight: bold;
    margin-top: 14px;
}

.saleDesc{
    margin-top: 14px;
}

.saleBtn{
    background-color: #0071c2;
    border-radius: 4px;
    padding: 7px 10px;
    margin-top: 40px;
    font-weight: bold;
}

.saleBtn:hover{
    background-color: #003580;
}