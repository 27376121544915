.header{
    background-color: #003580;
    color: white;
    display: flex;
    justify-content: center;
    position: relative;
}

.headerContainer{
    width: 100%;
    max-width: 1024px;
    margin: 20px 0 100px 0;
}

.headerList{
    display: flex;
    gap: 50px;
}

.headerListItem{
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerListItem.active{
    border: 1px solid white;
    padding: 10px;
    border-radius: 20px;
}

.headerDescription{
    margin: 20px 0;
}

.headerBtn{
    color: white;
    background-color: #0071c2;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.headerBtn:Hover{
    background-color: #003580;
}

.headerSearch{
    height: 30px;
    background-color: white;
    border:3px solid #febb02;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    position: absolute;
    bottom: -25px;
    width: 100%;
    max-width: 1024px;
    padding: 30px;
    -webkit-box-shadow: -1px 2px 14px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 2px 14px -4px rgba(0,0,0,0.75);
    box-shadow: -1px 2px 14px -4px rgba(0,0,0,0.75);;
}

.headerIcon{
    color: lightgray;
}

.headerSearchItem{
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerSearchInput{
    border: none;
    outline: none;
}

.headerSearch{
    color: black;
    cursor: pointer;
}

.date{
    position: absolute;
    top: 60px;
    z-index: 2;
}

.options{
    position: absolute;
    top: 50px;
    background-color: white;
    color: gray;
    border-radius: 5px;
    -webkit-box-shadow: 9px 13px 5px 0px rgba(0,0,0,0.33);
    -moz-box-shadow: 9px 13px 5px 0px rgba(0,0,0,0.33);
    box-shadow: 9px 13px 5px 0px rgba(0,0,0,0.33);
    z-index: 2;
}

.optionItem{
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.optionCounterButton{
    width: 30px;
    height: 30px;
    padding: 1px 5px;
    border: 1px solid #0071c2;
    color: #0071c2;
    cursor: pointer;
    background-color: white;
}

.optionCounter{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: black;
}

.optionCounterButton:disabled{
    cursor: not-allowed;
}

.headerContainer.listmode{
    margin: 20px 0 0 0;
}

.headerSearchItem input{
    border: solid 2px #febb02;
    border-radius: 4px;
}

