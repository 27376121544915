.homeContainer{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.featured{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    z-index: 1;
}

.featuredItem{
    position: relative;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
    width: 25%;
}

.featuredImg{
    width: 100%;
    object-fit: cover;
    height: 70%;
    border-radius: 10px;
}

.featuredTitles{
    position: absolute;
    left: 10px;
    color: white;
    font-size: 25px;
    background-color: #0071c2;
    bottom: 80px;
    border-radius: 6px;
    padding: 5px 8px;
    font-weight: bold;
}

.featuredTitles:Hover{
    background-color: #003580;
}

.homeTitle{
    width: 1024px;
    font-size: 20px;
}

