.offer{
    width: 1024px;
    background-image: url("https://images.pexels.com/photos/591383/pexels-photo-591383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-position: center;
    background-size: cover;
    height: 150px;
    color: white;
    padding: 12px;
    border-radius: 8px;
}

.offerContainer{
    display: flex;
    justify-content: center;
}

.offer h3{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 3px;
}

.offerBtn{
    background-color: #0071c2;
    border-radius: 4px;
    padding: 8px;
    margin-top: 23px;
    font-size: 14px;
    font-weight: bold;
}

.offerBtn:hover{
    background-color: #003580;
}