.hContainer{
    border: 1px solid lightgray;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 8px;
    -webkit-box-shadow: -1px 2px 14px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 2px 14px -4px rgba(0,0,0,0.75);
    box-shadow: -1px 2px 14px -4px rgba(0,0,0,0.75);;
}

.hImg{
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.hDesc{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
}

.hSubtitle{
    font-size: 12px;
    font-weight: bold;
}

.hFeatures{
    font-size: 12px;
    font-weight: bold;
    color: #008009;
}

.hRefund{
    margin-top: 10px;
}

.hSide{
    display: flex;
    gap: 20px;
    margin-left: 150px;
    font-size: 20px;
    color: #003580;
    font-weight: bold;
    position: relative;
}

.hDetailText{
    text-align: right;
    display: flex;
    gap: 5px;
    margin-left: 50px;
    align-items: center;
}

.hPrice{
    font-size: 27px;
    color: #003580;
    font-weight: bold;
    margin-bottom: 2px;
    display: flex;
    margin-left: auto;
    margin-right: 20px;
}

.hDetails{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.hTaxiOp{
    font-size: 14px;
    background-color: #008009;
    color: white;
    padding: 4px 6px;
    height: 25px;
    border-radius: 5px;
    width: max-content;
    margin-left: -2px;

}

.hTitle{
    font-weight: bold;
    font-size: 25px;
    color: #003580;
}

.hCost {
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 51px;
    left: -100px;
    font-size: 17px;
    background-color: #295899;
    color: white;
    padding: 6px;
    border-radius: 7px;
    font-weight: lighter;
    width: 200px;
}

.column {
    display: flex;
    flex-direction: column;
}


div{
    gap: 7px;
}

.hRating{
    width: 115px;
}
