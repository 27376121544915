.footer {
    padding: 1rem 7rem;
    color: white;
    width: 1239px;
    margin: 0 auto;
}
.footerContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-top: solid 2px white;
    border-bottom: solid 2px white;
    background-image: url("../images/hotels/background-image.jpg");
}
.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}
.top p {
    color: #fafafa88;
}
.top i {
    color: white;
    font-size: 1.5rem;
    margin-left: 1rem;
}
.top i:hover {
    color: #97dfe2;
}
.bottom {
    padding-top: 2rem;
    display: flex;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}
.bottom div {
    display: flex;
    flex-direction: column;
}
.bottom h4 {
    font-size: 1.2rem;
    padding: 1rem 0 0.8rem 0;
}
.bottom a {
    text-decoration: none;
    color: #fafafa88;
    padding-bottom: 0.4rem;
    font-size: 1rem;
}

@media screen and (max-width: 850px) {
    .footer {
        padding: 4rem 2rem;
    }
    .footer i {
        margin: 1rem 1rem 0 0;
    }
    .bottom div {
        width: 50%;
    }
}
.footer__copyright {
    text-align: center;
    font-size: 17px;
    color: white;
    width: 100%;
    margin-top: -18px;
    margin-bottom: 18px;
}
.footerTitles{
    margin: 0 auto;
}
.footerTitles span{
    color: white;
}
.footerIcons a{
    color: white;
    font-size: 18px;
    margin: 0 auto;
}
.footerIcons{
    height: 100%;
}