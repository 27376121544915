.nav{
    background-color: #003580;
    padding-bottom: 15px;
}

.listContainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    background-color: white;
    padding: 40px;
}

.listWrapper{
    width: 100%;
    max-width: 1024px;
    display: flex;
    gap: 20px;
}

.lsSearch{
    flex: 1;
    background-color: #febb02;
    padding: 10px;
    border-radius: 10px;
    position: sticky;
    top: 10px;
    height: max-content;
}

.lsTitle{
    font-size: 20px;
    color: #555;
    margin-bottom: 10px;
    font-weight: bold;
}

.listResult{
    flex: 3;
}

.lsItem{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.lsItem>label{
    font-size: 12px;
}

.lsItem>input{
    height: 30px;
    border: none;
    padding: 5px;
}

.lsItem>span{
    height: 30px;
    padding: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.lsOptionItem{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    color: #555;
    font-size: 12px;
}

.lsOptionInput{
    width: 50px;
}

.lsOptions{
    padding: 10px;
}

.lsSearch>button{
    flex: 3;
    background-color: #0071c2;
    color: white;
    border: none;
    width: 100%;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
}

.lsSearch>button:Hover{
    background-color: #003580;
}