.trendingContainer {
    gap: 10px;
    max-width: 1024px;
    display: inline-block;
}

.tFirstRow{
    width: 1024px;
    display: flex;
    gap: 25px;

}

.tSecondRow{
    width: 1024px;
    display: flex;
    gap: 25px;
    margin-top: 20px;
}

.trendingItem{
    position: relative;
    object-fit: fill;
    width: 520px;
    height: auto;
}

.trendingItem2{
    position: relative;
    object-fit: fill;
    width: 520px;
    height: auto;
}

.trendingItem2 img{
    width: 330px;
    height: 200px;
}

.country {
    position: absolute;
    top: 10px;
    left: 10px;
    margin: 0;
    padding: 5px;
    color: white;
    font-weight: bold;
    font-size: 25px;
    display: flex;
    text-shadow: 1px 2px 0px rgba(0,0,0,0.6);
}

.country img{
    width: 50px;
    height: 35px;
    margin-left: 15px;
}

.trendingImg{
    border-radius: 6px;
    width: auto;
    height: 373px;
}

.trendingTitle h2{
    color: white;
    font-weight: bold;
    margin: 20px 0;
    font-size: 30px;
}

.trendingTitle p{
    color: white;
    margin-bottom: 30px;
    margin-top: -15px;
}