.searchItem{
    border: 1px solid lightgray;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.siImg{
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.siDescription{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
}

.siTaxiOp{
    font-size: 12px;
    background-color: #008009;
    color: white;
    padding: 3px 5px;
    border-radius: 5px;
    width: max-content;
}

.siSubtitle{
    font-size: 12px;
    font-weight: bold;
}

.siFeatures{
    font-size: 12px;
    font-weight: bold;
    color: #008009;
}

.siCancelOpSubtitle{
    font-size: 12px;
    color: #008009;
}

.siDetails{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.siRating{
    display: flex;
    justify-content: space-between;
}

.siRating>button{
    background-color: #003580;
    color: white;
    padding: 5px;
    font-weight: bold;
    border: none;
}

.siDetailTexts{
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.siPrice{
    font-size: 17px;
    color: black;
    font-weight: bold;
}

.siCheckButton{
    background-color: #0071c2;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px ;
}

.siCheckButton:Hover{
    background-color: #003580;
}