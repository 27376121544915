.coContainer{
    width: 1024px;
    margin: 0 auto;
    padding: 4px;
}

.coHotel{
    margin-top: 50px;
    width: 1000px;
    padding: 13px;
}

form{
    padding: 13px;
}
.coCardDetails{
    background-color: #0071c2;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 50px;
}

.coTitle{
    margin-bottom: 15px;
    color: white;
    font-size: 30px;

}

.coPaymentTitle{
    margin-bottom: 15px;
    color: white;
    font-size: 25px;
}

.coAdress{
    background-color: #0071c2;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 50px;
}

.btnCheckout{
    color: white;
    font-size: 25px;
    font-weight: bold;
    background-color: #0071c2;
    border-radius: 6px;
    padding: 4px 10px;
    margin-top: 15px;
}

.btnCheckout:hover{
    font-size: 26px;
    transition: all ease;
}

.coInputContainer{
    display: flex;
}

.payFormCont{
    background-color: #003580;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding-top: 6px;
    padding-bottom: 8px;
    margin-bottom: 4px;
}

.coLabel{
    display: block;
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    width: 13%;
}

.coInput{
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 0.5rem 0.75rem;
    color: #4a5568;
    line-height: 1.25;
    outline: 0;

}

.coInputContainer{
    margin: 8px 15px;

}
